import * as React from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Footer from '../components/Footer'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const NotFoundPage = ({ data }) => {
    const heroBg = convertToBgImage(getImage(data.heroBg.childImageSharp))

    return (
        <>
            <SEOTools title='Page not found'>
                <meta name='robots' content='noindex' />
            </SEOTools>

            <BackgroundImage Tag='section' {...heroBg} preserveStackingContext className='dark-background-overlay'>
                <StyledJumbotron fluid className='text-white mb-0 d-flex align-items-center full-screen-jumbo'>
                    <Container className='text-center my-5 py-5'>
                        <Row className='d-flex justify-content-center text-center'>
                            <Col md='8'>
                                <h1>404</h1>
                                <p className='h2'>This is not the webpage you are looking for.</p>
                            </Col>
                        </Row>
                    </Container>
                </StyledJumbotron>
            </BackgroundImage>

            <Footer />
        </>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "sirumobile_404.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 3840, placeholder: BLURRED, quality: 80, transformOptions: { cropFocus: CENTER })
            }
        }
    }
`

const StyledJumbotron = styled(Jumbotron)`
    h1 {
        font-family: 'Open Sans', sans-serif;
        font-size: 6rem;
    }
`

export default NotFoundPage
